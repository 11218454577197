//
import { I18n } from "i18n-js";

// The variable 'USER_LOCALE' is used to fetch the user's locale (language preference) from the 'lang' attribute of the HTML document's root element.
// This locale value is set in the 'app/views/layouts/application.html.erb' file of the application.
// The value is primarily inferred from the 'ACCEPT-LANGUAGE' HTTP header, which is sent by the client's browser and indicates the user's preferred languages.
// If the 'lang' attribute is not set or is undefined, the default value 'en' (English) is used.
const USER_LOCALE = document.documentElement.lang || 'fr';

// Use require instead of import for dynamic file paths
const translations = require(`./locales/${USER_LOCALE}.json`);

export const i18n = new I18n();
i18n.translations = translations;
i18n.defaultLocale = USER_LOCALE;
i18n.locale = i18n.defaultLocale;
i18n.fallbacks = true;
i18n.store(translations);
